import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { primaryKey } from "@mswjs/data"
import { Account } from "app/types/account";
import { Efficiency } from "../../app/types/efficiency";
import Horoscope from "../../features/horoscpoe/horoscope";
import {HoroscopeForecast} from "../../app/types/horoscope";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export const HoroscopeForecastModel = defineModel<HoroscopeForecast>(() => ({
  id: primaryKey(String),
  week: Number,
  day: Number,
  sign: Number,
  group: Number,
  percent: Number,
  summary: String,
  content: String,
}))
