import { http, HttpResponse } from "msw";
import db, { accounts, defaultMockAuthenticationToken } from "../db";
import { ErrorNotAuthorized, ErrorNotFound } from "./errors";
import { AccountUpdateRequest } from "app/services/account";
import { authorizationHeader } from "./common";
import {HoroscopeForecastResponse} from "../../app/services/horoscope";

export const horoscopeForecastFetch = http.get("/v1/horoscope",
  async ({ request }) => {

    const header = request.headers.get(authorizationHeader);
    if (header !== `Bearer ${defaultMockAuthenticationToken}`) {
      return HttpResponse.json(ErrorNotAuthorized, { status: 401 });
    }


    let HoroscopeForecast: HoroscopeForecastResponse = {
      id: "0",
      week: 35,
      day: 0,
      sign: 0,
      summary: "Horoscope summary",
      content: "Horoscope summary content"
    };

    return HttpResponse.json(HoroscopeForecast, { status: 200 });
  });